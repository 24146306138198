import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "broadcast-message",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      broadcastMessageId: null,
      valid: false,
      cities: [],
      search: "",
      citiesIsLoading: false,
      broadcastMessage: {
        subject: "",
        body: "",
        schedule: null,
        sent: false,
        allCities: false,
        cities: null,
        forShopUsers: false
      },
      dateScheduleMenu: false
    };
  },
  created() {
    this.searchCities();
    this.broadcastMessageId = this.$route.params.broadcastMessageId;
    let title =
      this.broadcastMessageId != null ? "Broadcast message details" : "Insert new broadcast message";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.broadcastMessageId != null) {
      ApiService.get(`api/broadcast-message`, `${this.broadcastMessageId}`)
        .then(response => {
          this.$log.debug("Broadcast message: ", response.data);
          this.broadcastMessage = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    searchCities() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
          .then(res => {
            this.cities = res.data;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.citiesIsLoading = false));
    },
    removeCityFromList(item) {
      const index = this.broadcastMessage.cities.map(function(e) { return e.id; }).indexOf(item.id);
      if (index >= 0)
        this.broadcastMessage.cities.splice(index, 1);
    },
    previousState() {
      this.$router.go(-1);
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveBroadcastMessage(this.broadcastMessage);
      }
    },
    saveBroadcastMessage(broadcastMessage) {
      this.loading = true;
      let cities = [];
      if(broadcastMessage.cities != null && broadcastMessage.cities.length > 0 && typeof broadcastMessage.cities[0] === 'number'){
        broadcastMessage.cities.forEach(function(entry) {
          let city = {};
          city['id'] = entry;
          cities.push(city);
        });
        broadcastMessage.cities = cities;
      }

      if (broadcastMessage.id != null) {
        ApiService.put(`api/broadcast-message`, broadcastMessage)
          .then(response => {
            this.$log.debug("Broadcast message updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/broadcast-message`, broadcastMessage)
          .then(response => {
            this.$log.debug("Broadcast message created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.clearFields();
          this.$emit("input", value);
        }
      }
    },
    dateScheduleFormatted: {
      get() {
        if (this.broadcastMessage.schedule == null || this.broadcastMessage.schedule === "")
          return null;
        return moment
          .utc(this.broadcastMessage.schedule)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.broadcastMessage.schedule = value;
      }
    }
  }
};
